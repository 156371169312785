/*
 * @Description: 人员
 * @Author: 琢磨先生
 * @Date: 2022-05-23 01:53:02
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-23 14:30:04
 */

import http from "./axios_init.js";

export default {
  /**
   * 人员下拉框加载数据
   * @param {*} q
   * @param {*} deptId
   * @returns
   */
  get_select(q, deptId, jobId) {
    return http.get(
      `seller/v1/emp/select?q=${q}&deptId=${deptId ?? ""}&jobId=${jobId ?? ""}`
    );
  },
};
